import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import ReactMarkdownWithHtml from "react-markdown/with-html"
import SEO from "../../components/seo"
import style from "../press.module.scss"

const share = (title, desciption, url) => {
  const shareData = {
    title: title,
    text: desciption,
    url: url,
  }
  try {
    navigator.share(shareData)
    return true
  } catch (ex) {
    return false
  }
}

const PressPage = ({ data, location }) => {
  const press = data.allStrapiPress.edges[0].node

  let content = press.content
  try {
    content = press.content.replaceAll(
      /https:\/\/storage.googleapis.com\/ixdadb.appspot.com\/([\w]+)\/([\w]+)./g,
      `https://storage.googleapis.com/ixdadb.appspot.com/large_$1/large_$2.`
    )
  } catch (e) {}
  return (
    <Layout hero={false} headerBackground="/images/header.speaker.svg">
      <SEO
        title={press.title}
        image={press.og_image && press.og_image.childImageSharp.resize.src}
        description={
          press.description && press.description.replace(/(?:\r\n|\r|\n)/g, " ")
        }
      />
      <div className={style.fullContent}>
        <h1 className={style.fullTitle}>{press.title}</h1>
        <a
          className={[style.share, "cta"].join(" ")}
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
            data.site.siteMetadata.siteUrl + location.pathname
          )}`}
          rel="noreferrer"
          target="_blank"
          onClick={e => {
            if (
              share(
                press.title,
                press.description,
                data.site.siteMetadata.siteUrl + location.pathname
              )
            ) {
              e.preventDefault()
            }
          }}
        >
          <svg
            width="23px"
            height="21px"
            viewBox="0 0 23 21"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="講者_web_pop"
                transform="translate(-527.000000, -937.000000)"
                fillRule="nonzero"
              >
                <g id="Group-18" transform="translate(0.000000, -94.000000)">
                  <g
                    id="fa-solid:share"
                    transform="translate(527.000000, 1030.000000)"
                  >
                    <g
                      id="Icon"
                      transform="translate(0.000000, 1.000000)"
                      fill="#FFFFFF"
                    >
                      <path
                        d="M22.6267441,7.55026325 L14.7203145,0.774437415 C14.028248,0.18127123 12.9375,0.662803584 12.9375,1.58422852 L12.9375,5.15316746 C5.72174414,5.23515413 0,6.67038893 0,13.4569591 C0,16.1961341 1.77805273,18.9097635 3.74347461,20.3285029 C4.35679297,20.7712487 5.23088281,20.2155762 5.00474609,19.4978919 C2.96780859,13.0328932 5.97088086,11.3165676 12.9375,11.2171047 L12.9375,15.1365489 C12.9375,16.0594005 14.0291016,16.5387929 14.7203145,15.94634 L22.6267441,9.16984545 C23.1240742,8.74355044 23.124748,7.97713782 22.6267441,7.55026325 Z"
                        id="Icon-Path"
                      ></path>
                    </g>
                    <rect
                      id="ViewBox"
                      x="0"
                      y="0"
                      width="23"
                      height="23"
                    ></rect>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span className={style.hidden}>&nbsp;分享</span>
        </a>
        <div className={style.author}>
          <div>
            <img
              alt={press.author.name.name}
              src={press.author.avatar.childImageSharp.resize.src}
            />
          </div>
          <div>
            <h4>{press.author.name.name}</h4>
            {new Intl.DateTimeFormat("zh-TW").format(
              new Date(press.created_at)
            )}
          </div>
        </div>
        <ReactMarkdownWithHtml
          allowDangerousHtml={true}
          children={content}
        ></ReactMarkdownWithHtml>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allStrapiPress(filter: { site: { id: { eq: 2 } }, slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          pressPage: gatsbyPath(filePath: "/press/{StrapiPress.slug}")
          title
          description
          content
          author {
            avatar {
              childImageSharp {
                resize(width: 320, height: 320) {
                  src
                }
              }
            }
            name {
              name
              origional_name
            }
          }
          og_image {
            childImageSharp {
              resize(width: 1200, height: 600) {
                src
              }
            }
          }
          created_at
        }
      }
    }
  }
`
export default PressPage
